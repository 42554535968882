html,
body {
  padding: 5px;
  height: 100%;
  width: 100%;
  background-color: #fff5d7;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

.logo {
  width: 70px;
}

.App {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  grid-template-rows:
    80px 575px minmax(min-content, max-content) minmax(min-content, max-content)
    10px 10px;
  grid-template-areas:
    ". h h ."
    ". o t ."
    ". c v ."
    ". b b ."
    ". f f ."
    ". s s .";
}

@media screen and (max-width: 850px) {
  .App {
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows:
      80px 575px minmax(min-content, max-content) 575px minmax(
        min-content,
        max-content
      )
      minmax(min-content, max-content)
      10px 20px;
    grid-template-areas:
      ". h ."
      ". o ."
      ". c ."
      ". t ."
      ". v ."
      ". b ."
      ". f ."
      ". s .";
  }
  .App > .footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .App > .original {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
  }
  .App > .buttons_original {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .App > .translated {
    border-top-left-radius: 10px;
    padding: 10px;
  }
}

@media screen and (min-width: 851px) {
  .App > .original {
    padding: 10px 5px 0px 10px;
  }
  .App > .translated {
    padding: 10px 10px 0px 5px;
  }
}

.App > .header {
  grid-area: h;
  background-color: #ffaaab;
  border-radius: 10px;
  border: 1px;
  color: #2c1928;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
}

.header > .title {
  margin-left: auto;
  margin-right: auto;
  font-size: 2em;
  font-family: "Brush Script MT", cursive;
}

.header > .contact {
  font-family: "Brush Script MT", cursive;
}

.App > .original {
  grid-area: o;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App > .translated {
  grid-area: t;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App > .original,
.translated {
  background-color: #ebb855;
  margin-top: 10px;
}

.App > .original {
  border-top-left-radius: 10px;
}

.App > .translated {
  border-top-right-radius: 10px;
}

.App > .footer {
  grid-area: f;
  background-color: #ebb855;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.buttons_original > .side-heading {
  align-self: center;
  font-size: 1.5em;
  font-family: "Brush Script MT", cursive;
}

.App > .buttons_original,
.buttons_translated {
  background-color: #ebb855;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
}

.App > .buttons_original {
  grid-area: c;
  padding: 5px 5px;
}

.App > .buttons_translated {
  grid-area: v;
  padding: 5px 10px;
}

.ace_editor {
  border-radius: 10px;
}

button {
  color: #000000;
  background-color: #ed7476;
  border: none;
  outline: none;
  box-shadow: none;

  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  font-weight: bold;
  padding: 0px 30px;

  text-align: center;
  text-decoration: none;
  height: 40px;
}

button:hover {
  background-color: #f19a9b;
}

button:disabled {
  color: #a05a5a;
  cursor: default;
  background-color: #ed7476;
}

button:disabled:hover {
  background-color: #ed7476;
}

.heading {
  font-size: 1.5em;
  margin: 10px;
  font-family: "Brush Script MT", cursive;
}

.expand {
  margin: auto 0;
}

.errors {
  overflow-y: auto;
  height: 80px;
  background-color: #ed7476;
  width: 100%;
  border-radius: 10px;
  font-style: italic;
  text-align: center;
  margin: 0px 10px 10px 10px;
  padding: 10px 5px;
  white-space: normal;
}

.App > .batch {
  grid-area: b;
  background-color: #ebb855;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
  grid-auto-flow: dense;
  justify-content: center;
  gap: 5px;
  padding: 10px 0 0 0;
}

.batch > .batch-item {
  padding: 5px 10px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.App > .space {
  grid-area: s;
}

.loading {
  height: 16px;
  width: 16px;
  margin-right: 2px;
}

.static-prompt-small {
  height: 100px;
  overflow-y: auto;
}

.static-prompt-large {
  height: 400px;
  overflow-y: auto;
}

.setting {
  width: 100%;
  height: 40px;
  display: flex;
}

.pass {
  border-radius: 10px;
  margin-right: auto;
  width: 100%;
  margin-right: 10px;
}

.model {
  border-radius: 10px;
  margin-right: auto;
  width: 20ch;
  margin-right: 10px;
}
